<template>
    <nav v-if="menuItems.length > 0">
      <ul :class="menuClasses">
        <li v-for="menuItem in menuItems" :key="menuItem.id" :class="itemClasses">
          <NuxtLink :to="{ path: menuItem.uri }" class="font-medium  hover:text-primary/80 transition" active-class="text-secondary">
            {{menuItem.label}}
          </NuxtLink>
        </li>
      </ul>
    </nav>
</template>

<script setup lang="ts">

const props = defineProps(['menuLocation', 'menuClasses', 'itemClasses']);

const menuItems = ref([]);

const query = gql`
  query getMenu {
  menuItems(where: {location: ${props.menuLocation}}) {
    edges {
      node {
        id
        label
        order
        target
        uri
        childItems {
          edges {
            node {
              id
              label
              order
              target
              uri
            }
          }
        }
      }
    }
  }
}`
const { data } = await useAsyncQuery(query);

if (data.value?.menuItems) {
  if (data.value.menuItems.edges) {
    menuItems.value = data.value.menuItems.edges.map((item) => {
      return item.node;
    });
  }
}
</script>
